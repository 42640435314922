 import "es6-promise/auto";

 import {
     setContent
 } from "viewport-extra";

var ua = navigator.userAgent;
var isSmartPhone =
  ua.indexOf("iPhone") > -1 || (ua.indexOf("Android") > -1 && ua.indexOf("Mobile") > -1);

var isTablet =
  !isSmartPhone &&
  (ua.indexOf("iPad") > -1 ||
    (ua.indexOf("Macintosh") > -1 && "ontouchend" in document) ||
    ua.indexOf("Android") > -1);

window.addEventListener("DOMContentLoaded", (e) => {
  if (isSmartPhone) {
    setContent({
      minWidth: 375,
    });
  } else if (isTablet) {
    setContent({
      minWidth: 1160,
    });
  }
});


import $ from "jquery";

/**
 * js_slick
 *
 */
//import 'slick-carousel';
//$(function () {
//    var $jsSlick = $('.js_slick');
//    if ($jsSlick[0]) {
//        $('.js_slick').slick({
//            autoplay: true,
//            autoplaySpeed: 5000,
//            slidesToShow: 1,
//            slidesToScroll: 1,
//            dots: true,
//            arrow: true,
//            prevArrow: '<img src="/_assets/img/icons/icn_arrow.svg" class="slide-arrow prev-arrow">',
//            nextArrow: '<img src="/_assets/img/icons/icn_arrow.svg" class="slide-arrow next-arrow">',
//            responsive: [{
//                breakpoint: 750,
//                settings: {
//                    //            edge: true,
//                }
//    }]
//        });
//    }
//});
/**
 * js_objectFitImages
 *
 */
import objectFitImages from "object-fit-images";
if ($(".js_ofiImg")[0]) {
  objectFitImages(".js_ofiImg");
}

/**
 * js_anime
 *
 */
//import anime from 'animejs/lib/anime.es.js';

/**
 * js_scrollTrigger
 *
 */
import "./scrollTrigger_setting";


/**
 * js_simpleParallax
 *
 */
import simpleParallax from 'simple-parallax-js';
const jsParallax = document.getElementsByClassName('js_parallax');
if (jsParallax[0]) {
    new simpleParallax(jsParallax, {
        delay: 1,
        scale: 1.2
    });

    const jsParallax2 = document.getElementsByClassName('js_parallax2');
    new simpleParallax(jsParallax2, {
        delay: 1,
        orientation: 'up',
        scale: 1.5,
        overflow: true,
        maxTransition: 50
    });

    const jsParallax3 = document.getElementsByClassName('js_parallax3');
    new simpleParallax(jsParallax3, {
        delay: 2,
        orientation: 'up',
        scale: 1.5,
        overflow: true
    });

    const jsParallax4 = document.getElementsByClassName('js_parallax4');
    new simpleParallax(jsParallax4, {
        delay: 1.2,
        orientation: 'down',
        scale: 1.3,
        overflow: true
    });
}


/**
 * js_vivus
 *
 */
import * as Vivus from 'vivus';
if ($("#mask")[0]) {
    $("#splash_logo").css({
        'opacity': 1,
    });
    var stroke;
    stroke = new Vivus('mask', {
            start: 'manual',
            type: 'scenario-sync',
            duration: 3,
            forceRender: false,
            animTimingFunction: Vivus.EASE,
        },
        function () {
            $("#mask").attr("class", "done");
            //            $("#splash_logo").fadeOut('slow');
            $("#splash_logo").addClass('splash_logo__scale');
            $("#splash").delay(500).fadeOut('slow', function () {
                $('body').addClass('appear');
            });

        }
    );

    $(window).on('load', function () {
        stroke.play();
    });
}


import 'lazysizes';
/**
 * js_common
 *
 */
import "./slider";
/**
 * js_common
 *
 */
import "./common";


