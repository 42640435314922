/**
 * img preload
 *
 */
var img_folder = "/_assets/images";

var preload_images = [
	img_folder + "/top/icn_about_01.png",
    img_folder + "/top/icn_about_02.png",
    img_folder + "/top/icn_about_03.png",
    img_folder + "/top/icn_about_04.png",
    img_folder + "/top/icn_about_05.png",
    img_folder + "/top/icn_about_06.png",
    img_folder + "/top/icn_about_07.png",
    img_folder + "/top/icn_works_01.png",
    img_folder + "/top/icn_works_02.png",
    img_folder + "/top/icn_works_02.png",
    img_folder + "/top/icn_works_04.png",
    img_folder + "/top/icn_works_05.png",
    img_folder + "/top/icn_works_06.png",
];

document.addEventListener('DOMContentLoaded', function () {
    for (var i = 0; i < preload_images.length; i++) {
        var img = new Image();
        img.src = preload_images[i];
    }
});

/**
 * body fadeIn
 *
 */

if ($('body').not('.ly_top')[0]) {
    $(window).on('load', function () {
        $('body').animate({
            opacity: 1
        }, 800);
        if ($('.bl_animateSquare')[0]) {
            setTimeout(function () {
                $('.bl_animateSquare').animate({
                    opacity: 1
                }, 2000);
            }, 1000);
        }
    });
}

/**
 * body fadeIn
 *
 */

//if ($('.ly_top')[0]) {
//    $(window).on('load', function () {
//        $("#splash-logo").delay(1200).fadeOut('slow');
//        $("#splash").delay(1500).fadeOut('slow', function () {
//            $('body').addClass('appear');
//        });
//        $('.splashbg').on('animationend', function () {});
//    });
//}

/**
 * back top
 *
 */
$(document).ready(function () {
    var bodyHeight = $('body').outerHeight(true);
    var pagetop = $(".js_pageTop");
    pagetop.hide();
    $(window).on("scroll", function () {
        if ($(this).scrollTop() > bodyHeight) {
            pagetop.fadeIn("fast");
        } else {
            pagetop.fadeOut("fast");
        }

        var scrollHeight = $(document).height();
        var scrollPosition = $(window).height() + $(window).scrollTop();
        var footHeight = $(".js_footer").innerHeight();

        if (scrollHeight - scrollPosition <= footHeight) {
            pagetop.css({
                "position": "absolute",
                "bottom": footHeight + 10
            });
        } else {
            pagetop.css({
                "position": "fixed",
                "bottom": "10px"
            });
        }




    });
    pagetop.click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 500);
        return false;
    });


});





/**
 * back top
 *
 */
//$(document).ready(function () {
//    var scrollHeight = $(document).height();
//    var scrollPosition = $(window).height() + $(window).scrollTop();
//    console.log(scrollPosition);
//    $(window).on("scroll", function () {
//        if ($(this).scrollTop() > bodyHeight) {
//            pagetop.fadeIn("fast");
//        } else {
//            pagetop.fadeOut("fast");
//        }
//
//        var scrollHeight = $(document).height();
//        var scrollPosition = $(window).height() + $(window).scrollTop();
//        var footHeight = $(".js_footer").innerHeight();
//
//        if (scrollHeight - scrollPosition <= footHeight) {
//            pagetop.css({
//                "position": "absolute",
//                "bottom": footHeight + 10
//            });
//        } else {
//            pagetop.css({
//                "position": "fixed",
//                "bottom": "10px"
//            });
//        }
//    });
//});

/**
 * reload breakpoint
 *
 */
//if ($('.ly_top')[0]) {
const breakPoint01 = 751;
const breakPoint02 = 750;
let resizeFlag01;
let resizeFlag02;

window.addEventListener('load', function () {
    if (breakPoint01 < window.innerWidth) {
        resizeFlag01 = false;
    } else {
        resizeFlag01 = true;
    }
    resizeWindow01();
}, false);

window.addEventListener('load', function () {
    if (breakPoint02 > window.innerWidth) {
        resizeFlag02 = false;
    } else {
        resizeFlag02 = true;
    }
    resizeWindow02();
}, false);

const resizeWindow01 = function () {
    window.addEventListener('resize', function () {
        if (breakPoint01 < window.innerWidth && resizeFlag01) {
            window.location.reload();
            resizeFlag01 = false;
        } else if (breakPoint01 >= window.innerWidth && !(resizeFlag01)) {
            resizeFlag01 = true;
        }
    }, false);
}
const resizeWindow02 = function () {
    window.addEventListener('resize', function () {
        if (breakPoint02 > window.innerWidth && resizeFlag02) {
            window.location.reload();
            resizeFlag02 = false;
        } else if (breakPoint01 <= window.innerWidth && !(resizeFlag02)) {
            resizeFlag02 = true;
        }
    }, false);
}
//}

/**
 * js_ofiImg
 *
 */
if ($(".js_ofiImg")[0]) {
    objectFitImages();
}

/**
 * js_ToggleMenu
 *
 */
$(function () {
    //    if ($(window).width() < 751) {
    $('.js_accordion_title').on('click', function () {
        $(this).next().fadeToggle(600);
        $(this).toggleClass('is_open', 200);

    });
    //    }
});

/**
 * link delay
 *
 */
function btnClick() {
    event.preventDefault();
    var thisLink = this.href;

    function getLink() {
        window.location.href = thisLink;
    }
    setTimeout(getLink, 800);
}

let buttons = document.querySelectorAll('.bubbly-button');

for (let i = 0; i < buttons.length; i++) {
    buttons[i].addEventListener('click', btnClick);
}


/**
 * bubblyButtons
 *
 */
var animateButton = function (e) {

    e.preventDefault;

    //    e.target.closest('.bubbly-button').classList.remove('animate');

    e.target.closest('.bubbly-button').classList.add('animate');
    setTimeout(function () {
        e.target.closest('.bubbly-button').classList.remove('animate');
    }, 700);
};

var bubblyButtonsTrigger = document.getElementsByClassName("bubbly-button_inner");

for (var i = 0; i < bubblyButtonsTrigger.length; i++) {
    bubblyButtonsTrigger[i].addEventListener('mousedown', animateButton, false);
}


/**
 * ripple1
 *
 */
$(function () {
    var $clickable = $('.bubbly-button');
    $clickable.on('mouseenter', function (e) {
        var _self = this;
        var x = e.offsetX;
        var y = e.offsetY;

        var $effect = $(_self).find('.ripple__effect');
        var w = $effect.width();
        var h = $effect.height();
        $effect.css({
            left: x - w / 2,
            top: y - h / 2
        });

        if (!$effect.hasClass('is-show')) {
            $effect.addClass('is-show');
            setTimeout(function () {
                $effect.removeClass('is-show');
            }, 700);
        }
        return false;
    });
});


/**
 * ripple2
 *
 */
$(function () {
    var $clickable = $('.ripple__btn');
    $clickable.on('mouseenter', function (e) {
        var _self = this;
        var x = e.offsetX;
        var y = e.offsetY;

        var $effect = $(_self).find('.ripple__effect');
        var w = $effect.width();
        var h = $effect.height();
        $effect.css({
            left: x - w / 2,
            top: y - h / 2
        });

        if (!$effect.hasClass('is-show')) {
            $effect.addClass('is-show');
            setTimeout(function () {
                $effect.removeClass('is-show');
            }, 700);
        }
        return false;
    });
});

/**
 * Gnav
 *
 */
(function () {
    'use strict';

    var $mainButton = $(".main-button"),
        $closeButton = $(".close-button"),
        $buttonWrapper = $(".button-wrapper"),
        $ripple = $(".ripple"),
        $layer = $(".layered-content"),
        $main = $("main"),
        $pool = $("#pool"),
        $body = $('body'),
        $buttonWrapper2 = $(".button-wrapper_wrapper");


    $mainButton.on("click", function () {
        $buttonWrapper.addClass("clicked").delay(300).queue(function (next) {
            $buttonWrapper2.addClass("active");
            setTimeout(function () {
                $layer.addClass("active");
                $pool.addClass("active");
                $main.addClass("is_hide");
                $body.css({
                    'overflow': 'hidden'
                });

            }, 300);
            next();
        });
    });

    $closeButton.on("click", function () {
        $layer.removeClass("active");
        $pool.removeClass("active");
        $body.css({
            'overflow': 'auto'
        });
        $buttonWrapper.removeClass("clicked");
        setTimeout(function () {
            $main.removeClass("is_hide");
            $buttonWrapper2.removeClass("active");
        }, 2500);
    });
})();

/**
 * top bg
 *
 */
if ($('.js_MV_top_imgWrap')[0]) {
    let background = document.getElementById('js_MV_top_img1');
    let background2 = document.getElementById('js_MV_top_img2');
    let background3 = document.getElementById('js_MV_top_img3');


    //    if ($(window).width() > 750) {
    document.addEventListener('mousemove', event => {

        var x = Math.round(event.pageX / 100);
        var y = Math.round(event.pageY / 100);

        background.style.marginLeft = x + '0px';
        background.style.marginTop = y + '0px';
        background2.style.marginLeft = '-' + x + '0px';
        background2.style.marginTop = '-' + y + '0px';
        background3.style.marginLeft = '-' + x + '0px';
        background3.style.marginTop = y + '0px';
    });
    //    }


    //    if ($(window).width() < 751) {
    //        function imgMove() {
    //            var numbers = '0123456789';
    //            var string = numbers;
    //            var len = Math.floor(Math.random() * 10);
    //            var len2 = Math.floor(Math.random() * 10);
    //            var len3 = Math.floor(Math.random() * 10);
    //            var ranNum = len;
    //            var ranNum2 = len2;
    //            var ranNum3 = len3;
    //
    //            background.style.marginLeft = ranNum + '6px';
    //            background.style.marginTop = ranNum2 + '6px';
    //            background2.style.marginLeft = '-' + ranNum3 + '2px';
    //            background2.style.marginTop = '-' + ranNum + '2px';
    //            background3.style.marginLeft = '-' + ranNum2 + '3px';
    //            background3.style.marginTop = ranNum3 + '3px';
    //        }
    //         setInterval(imgMove, 6000);
    //    }
    if ($(window).width() < 751) {
        function imgMove() {
            var numbers = '012345';
            var string = numbers;
            var len = Math.floor(Math.random() * 3);
            var len2 = Math.floor(Math.random() * 3);
            var len3 = Math.floor(Math.random() * 3);
            var ranNum = len;
            var ranNum2 = len2;
            var ranNum3 = len3;

            background.style.marginLeft = ranNum + '2px';
            background.style.marginTop = ranNum2 + '2px';
            background2.style.marginLeft = '-' + ranNum3 + '1px';
            background2.style.marginTop = '-' + ranNum + '1px';
            background3.style.marginLeft = '-' + ranNum2 + '1px';
            background3.style.marginTop = ranNum3 + '1px';
        }
        setInterval(imgMove, 6000);
    }
}

/**
 * js_makeRanNum
 *
 */
var makeRanNum = function () {
    //    var letters = 'abcdefghijklmnopqrstuvwxyz';
    //    var string = letters + letters.toUpperCase() + numbers;
    var numbers = '0123456789';
    var string = numbers;

    var len = Math.floor(Math.random() * 10);
    var ranNum = len;
    //    var ranNum = '';
    //    for (var i = 0; i < len; i++) {
    //        ranNum += string.charAt(Math.floor(Math.random() * string.length));
    //    }
    return ranNum;
}


/**
 * js_changeBg
 *
 */
$(window).on('load', function () {
    $(window).scroll(function () {
        if ($('.js_changeBgTrigger')[0]) {
            var js_changeBg = $('.js_changeBg');
            var js_changeBgTriggerArea = $('.js_changeBgTrigger').offset();
            var js_changeBgTriggerArea = js_changeBgTriggerArea.top;
            var scrollPosition = $(window).height() + $(window).scrollTop();
            //            console.log(js_changeBgTriggerArea);
            //            console.log(scrollPosition);

            if (scrollPosition > js_changeBgTriggerArea) {
                js_changeBg.addClass('is_change');
            } else {
                js_changeBg.removeClass('is_change');
            }
        }
    });
});
